var initTooltip = function (className, idName, attributeName) {
    var getPosition = function (element) {
        return element ? {
            top: element.getBoundingClientRect().top,
            left: element.getBoundingClientRect().left
        } : null;
    }

    var getDimensions = function (element) {
        return element ? {
            width: element.getBoundingClientRect().width,
            height: element.getBoundingClientRect().height
        } : null;
    }

    var
        maxWidth = 320,
        offset = 15,
        trigger = false,
        tooltip = false,
        title = false,
        content = false;

    var tooltipTriggers = document.getElementsByClassName(className);

    if (tooltipTriggers) {
        for (var i = 0; i < tooltipTriggers.length; i++) {
            tooltipTriggers[i].addEventListener('mouseover', function () {
                var trigger = this,
                    content = this.getAttribute(attributeName),
                    tooltip = document.createElement('span');

                if (!content || '' == content) return false;

                tooltip.id = idName;
                tooltip.innerHTML = content;
                tooltip.style.opacity = 0;
                document.body.appendChild(tooltip);

                trigger.removeAttribute(attributeName);

                var showTooltip = function () {
                    var width = getDimensions(tooltip).width;
                    width = width > maxWidth ? maxWidth : width;
                    tooltip.style.width = width + 'px';

                    // Left
                    var left = getPosition(trigger).left - (getDimensions(tooltip).width / 2) + (getDimensions(trigger).width / 2);

                    if (left < 0) {
                        left = offset;
                        tooltip.classList.add('left');
                    }

                    // Right
                    if (left + getDimensions(tooltip).width > document.documentElement.clientWidth) {
                        left = document.documentElement.clientWidth - getDimensions(tooltip).width - offset;
                    }

                    if (left < 0) {
                        left = offset;
                        width = document.body.clientWidth - (offset * 2);
                        tooltip.style.width = width + 'px';
                    }

                    // Top
                    var top = getPosition(trigger).top - getDimensions(tooltip).height - offset;
                    if (top < 0) {
                        top = getPosition(trigger).top + getDimensions(trigger).height + offset;
                        trigger.classList.add('bottom');
                    } else {
                        trigger.classList.remove('bottom');
                    }

                    tooltip.style.top = top + 'px';
                    tooltip.style.left = left + 'px';
                    trigger.classList.add(className + '--show');
                    tooltip.style.opacity = 1;
                }

                var hideTooltip = function () {
                    tooltip.style.opacity = 0;
                    trigger.classList.remove(className + '--show');
                    document.getElementById(idName) && document.body.removeChild(document.getElementById(idName));
                    trigger.setAttribute(attributeName, content);
                }

                showTooltip(trigger, tooltip);

                trigger.addEventListener('mouseleave', hideTooltip);
            });
        }
    }

}

window.addEventListener('DOMContentLoaded', function (event) {
    initTooltip('cc-tooltip__trigger', 'cc-tooltip__tip', 'title');
});